<template>
  <div>
    <div class="container-fluid log-in-bg">
      <div class="container center-div">
        <div class="container logo">
          <div class="row justify-content-center">
            <div class="col-12">
              <img src="../../assets/icons/logo-coopharma.png" class="logo" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12">
            <div class="card">
              <div class="card-body">
                <form>
                  <div class="row justify-content-center">
                    <h1 class="login-title">
                      {{ name }} Ingrese Su Nueva Contraseña
                    </h1>
                    <div class="col-12 inputContainer">
                      <div class="row justify-contain-center">
                        <div class="col-12">
                          <input
                            class="email"
                            type="password"
                            placeholder="Nueva Contraseña"
                            v-model="newPassword"
                          />
                          <p class="required" v-if="esNewPassword">
                            Debe suministrar un password valido
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <router-link class="forgot-btn" to="/">
                    <h5
                      style="font-size: 20px; font-weight: 300; text-align: center; margin-top: 20px"
                    >
                      ¿Recordaste tus credenciales?
                    </h5>
                  </router-link>
                  <div class="button-position">
                    <button @click="proccessPassword" class="btn submit-btn">
                      Procesar Nueva Contraseña
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="backdrop" class="loader">
      <div :class="loader ? 'loader-show' : ''" class="loader-container ">
        <img src="../../assets/loader.svg" alt="" />
        <p class="loader-text">Loading...</p>
      </div>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
export default {
  name: "PasswordRestore",
  data() {
    return {
      email: "",
      password: "",
      name: "",
      newPassword: "",
      esNewPassword: false,
      store: null,
      user: null,
      loader: false,
      backdrop: false
    };
  },
  watch: {
    newPassword() {
      if (this.newPassword === "") {
        this.esNewPassword = true;
      } else {
        if (this.newPassword.length < 6) {
          this.esNewPassword = true;
        } else {
          this.esNewPassword = false;
        }
      }
    }
  },
  mounted() {
    this.getStore(this.$route.params.store);
  },
  methods: {
    getStore(storeID) {
      let query = new Parse.Query("Store");
      query.count().then(storeCount => {
        query.limit(storeCount);
        query.include("employee");
        query.equalTo("objectId", storeID);
        query.find().then(store => {
          this.password = atob(store[0].attributes.logkey);
          this.email = store[0].attributes.employee.attributes.username;
          this.name = store[0].attributes.employee.attributes.fullName;
        });
      });
    },
    proccessPassword() {
      if (this.newPassword === "") {
        this.esNewPassword = true;
      } else {
        this.esNewPassword = false;
      }
      if (!this.esNewPassword) {
        this.loader = true;
        this.backdrop = true;
        this.workUser();
      }
    },
    workStore() {
      Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      }).then(result => {
        result.set("logkey", btoa(this.newPassword));
        result.save();
        this.loader = false;
        this.backdrop = false;
        alert("El Password ha sido cambiado proceda a iniciar session");
        Parse.User.logOut();
        this.$router.push("/");
      });
    },
    workUser() {
      Parse.User.logIn(this.email, this.password).then(() => {
        this.user = Parse.User.current();
        this.user.set("password", this.newPassword);
        this.user.set("userValidated", true);
        this.user.save().then(() => {
          this.workStore();
        });
      });
    }
  }
};
</script>
<style scoped>
.log-in-bg {
  height: 100vh;
  /* background-image: url("../assets/background panel admin.jpg"); */
  background-size: cover;
  background-color: #e0e0e0;
}

.logo {
  width: 60%;
  margin: auto;
  display: block;
  margin-top: 50px;
}
.inputContainer {
  padding-top: 20px;
}
.card {
  padding-top: 50px;
  padding-bottom: 40px;
  border-radius: 25px;
  background-color: transparent;
  border-style: none;
}

.login-title {
  text-align: center;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px !important;
  font-size: 25px;
}

.center-div {
  padding-top: 50px;
}

.icon {
  color: #424242;
  font-size: 20px;
}

.email,
.password {
  margin-bottom: 20px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #424242;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 300;
  background-color: transparent;
  text-align: center;
}

.forgot-btn {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.forgot-btn:hover {
  margin-top: 50px;
  text-decoration: none;
  color: #424242;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.button-position {
  display: flex;
  width: 420px;
  margin: auto;
  padding-top: 20px;
}

.submit-btn {
  background-color: #424242;
  border: none;
  padding: 8px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  width: 100%;
  height: 55px;
  margin: auto;
  display: block;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  /* margin: 5px; */
}
.required {
  font-size: 15px;
  padding: 5px;
  background-color: #ffcccc;
}

.submit-btn2 {
  background-color: #ffc93a;
  border: none;
  padding: 8px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  width: 200px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  margin: 5px;
}
.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader-show {
  transform: scale(1) !important;
}

.loader {
  width: 90%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.loader-text {
  font-family: "segoe ui";
  font-style: italic;
  font-size: 20px;
}
</style>
